<template>
  <Form
    ref="formRef"
    :model="form"
    :rules="rules"
    class="pb-6"
    @submit="() => emit('submit', form)"
  >
    <PlanItem
      :plan="plan"
      class="mb-4"
    />

    <InputBlock
      :label="useT('event.title')"
      prop="title"
    >
      <TextInput
        v-model="form.title"
        :placeholder="useT('event.title.placeholder')"
        :maxlength="140"
      />
    </InputBlock>

    <div class="sm:grid sm:grid-cols-2 sm:gap-x-3">
      <InputBlock
        :label="useT('event.start')"
        prop="startDate"
      >
        <DatePicker
          v-model="form.startDate"
          :placeholder="useDayjs().format('DD. MM. YYYY')"
          :timezone="form.timezone"
          disabled-past
          time
        />
        <div v-if="!isTimezoneInputVisible">
          <div class="text-xs text-gray-500 mt-1">
            {{ currentTimezoneOption?.text }}
            <a
              href="#"
              class="underline transition hover:text-gray-700"
              @click.prevent="isTimezoneInputVisible = true"
            >
              {{ useT('common.edit') }}
            </a>
          </div>
        </div>
      </InputBlock>

      <InputBlock
        :label="useT('event.end')"
        prop="endDate"
      >
        <DatePicker
          v-model="form.endDate"
          :placeholder="useDayjs().add(1, 'day').format('DD. MM. YYYY')"
          :timezone="form.timezone"
          disabled-past
          time
        />
      </InputBlock>
    </div>

    <el-collapse-transition>
      <div v-show="isTimezoneInputVisible">
        <InputBlock
          :label="useT('event.timezone')"
          prop="timezone"
        >
          <Select
            v-model="form.timezone"
            filterable
            :options="getTimezoneOptions(form.startDate)"
          />
        </InputBlock>
      </div>
    </el-collapse-transition>

    <template v-if="!form.isOnlineOnly">
      <InputBlock
        :label="useT('event.venue')"
        prop="location.placeName"
      >
        <PlaceInput
          v-model="form.location.placeName"
          @suggestion-select="onPlaceSelected"
        />
      </InputBlock>

      <div class="grid grid-cols-2 gap-x-3">
        <InputBlock
          :label="useT('common.address.address')"
          prop="location.address"
          class="col-span-2 sm:col-auto"
        >
          <TextInput
            v-model="form.location.address"
          />
        </InputBlock>

        <InputBlock
          :label="useT('common.address.city')"
          prop="location.city"
        >
          <TextInput
            v-model="form.location.city"
          />
        </InputBlock>

        <InputBlock
          :label="useT('common.address.postcode')"
          prop="location.zipCode"
        >
          <TextInput
            v-model="form.location.zipCode"
          />
        </InputBlock>

        <InputBlock
          :label="useT('common.address.country')"
          prop="location.country"
          class="col-span-2 sm:col-auto"
        >
          <TextInput
            v-model="form.location.country"
          />
        </InputBlock>
      </div>
    </template>

    <InputBlock
      v-if="!['basic', 'professional'].includes(props.plan?.config?.tier)"
      prop="isOnlineOnly"
    >
      <Checkbox
        v-model="form.isOnlineOnly"
        :label="useT('event.onlineOnly')"
      />
    </InputBlock>

    <InputBlock
      :label="useT('event.usedLanguages')"
      prop="langs"
    >
      <Select
        v-model="form.langs"
        multiple
        :options="useGeneralStore().langs"
      />
    </InputBlock>
  </Form>
</template>

<script setup>
const props = defineProps({
  event: {
    type: Object,
    default: () => ({}),
  },
  plan: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['submit']);

// Refs
const form = ref({
  title: '',
  startDate: '',
  endDate: '',
  location: {
    placeName: '',
    address: '',
    city: '',
    zipCode: '',
    country: '',
  },
  isOnlineOnly: false,
  contactEmail: '',
  langs: [useAuthStore()?.user?.locale],
});
const isTimezoneInputVisible = ref(false);

const formRef = ref(null);

const rules = computed(() => {
  const locationRules = {
    placeName: [useFormRules().required],
    address: [useFormRules().required],
    city: [useFormRules().required],
    zipCode: [useFormRules().required],
    country: [useFormRules().required],
  };
  return {
    title: [useFormRules().required],
    startDate: [
      useFormRules().required,
      {
        ...useFormRules().startDate(computed(() => form.value.endDate)),
        message: useT('event.canNotStartBeforeEnd'),
      },
    ],
    endDate: [
      useFormRules().required,
      {
        ...useFormRules().endDate(computed(() => form.value.startDate)),
        message: useT('event.canNotEndBeforeStart'),
      },
    ],
    timezone: [useFormRules().required],
    langs: [
      useFormRules().required,
      {
        type: 'array',
        max: props.plan?.config?.tier === 'basic' ? 1 : undefined,
        message: useT('event.create.multiLanguageIsAvailableOnHigherPlans'),
        trigger: 'submit',
      },
    ],
    location: form.value.isOnlineOnly ? {} : locationRules,
  };
});

const currentTimezoneOption = computed(() => getTimezoneOptions(form.value?.startDate).find((option) => option?.id === form.value?.timezone));

// Methods
const onPlaceSelected = (location) => {
  form.value = {
    ...form.value,
    location: {
      ...location,
    },
  };
};

// Watchers
watch(() => form.value.isOnlineOnly, () => {
  if (form.value.isOnlineOnly) {
    form.value = {
      ...form.value,
      location: {
        placeName: '',
        address: '',
        city: '',
        zipCode: '',
        country: '',
      },
    };
  }
});

// Lifecycle
onMounted(() => {
  form.value = {
    ...form.value,
    ...props.event,
  };

  // prefill browser timezone
  form.value = {
    ...form.value,
    timezone: useDayjs.tz.guess() || '',
  };

  if (!form.value.timezone) {
    isTimezoneInputVisible.value = true;
  }
});

defineExpose({
  formRef,
});
</script>
