<template>
  <Dialog
    v-model="useDialogStore().eventCreate.isOpen"
    :size="selectedPlan ? 'small-hug' : 'small'"
    persistent
    @open="onOpen"
  >
    <template #header>
      <DialogHeader
        :title="selectedPlan ? useT('event.create.button') : useT('plan.selectPlan')"
        show-close
        @close="handleCancel"
      />
    </template>

    <div class="h-full">
      <transition
        name="custom-classes"
        :enter-from-class="isSlidingBack ? '-translate-x-4 opacity-0' : 'translate-x-4 opacity-0'"
        :leave-active-class="isSlidingBack ? 'translate-x-4 opacity-0' : '-translate-x-4 opacity-0'"
        class="transition-all duration-300"
        :duration="300"
        mode="out-in"
      >
        <EventCreateDialogPlan
          v-if="!selectedPlan"
          key="plan"
          :plans="plans"
          :is-plans-loading="usePlansStore().isPlansLoading"
          @set-plan="setPlan"
        />
        <EventCreateDialogEvent
          v-else
          ref="eventCreateDialogEventRef"
          key="event"
          :event="event"
          :plan="selectedPlan"
          @submit="(payload) => {
            event = payload;
            onSubmit();
          }"
        />
      </transition>
    </div>

    <template
      v-if="selectedPlan"
      #footer
    >
      <ButtonGroup
        align="right"
        type="primary"
        :text-submit="useT('common.create')"
        :text-cancel="plans.length > 1 ? useT('common.navigation.back') : useT('poly.cancel')"
        :loading="isSubmitting"
        @cancel="plans.length > 1 ? handleBack() : handleCancel()"
        @submit="() => eventCreateDialogEventRef.formRef.onSubmit()"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const isSubmitting = ref(false);
const isSlidingBack = ref(false);
const selectedPlan = ref(null);
const eventCreateDialogEventRef = ref(null);
const plans = ref([]);

const event = ref({
  location: {},
});

// Methods
const onOpen = () => {
  selectedPlan.value = null;
  event.value = {
    title: '',
    startDate: useDayjs().add(7, 'days').set('hour', 9).set('minute', 0).set('second', 0).utc().format('YYYY-MM-DDTHH:mmZZ'),
    endDate: useDayjs().add(7, 'days').set('hour', 21).set('minute', 0).set('second', 0).utc().format('YYYY-MM-DDTHH:mmZZ'),
    location: {
      placeName: '',
      address: '',
      city: '',
      zipCode: '',
      country: '',
    },
    isOnlineOnly: false,
    contactEmail: useAuthStore()?.user?.email,
    langs: [useAuthStore()?.user?.locale],
  };
};

const setPlan = (plan) => {
  isSlidingBack.value = false;
  selectedPlan.value = plan;
};

const fetchPlans = async() => {
  const response = await usePlansStore().fetchPlans({
    status: ['active'],
  });

  plans.value = response?.results || [];

  if (plans.value?.length === 1) {
    isSlidingBack.value = false;
    selectedPlan.value = plans.value[0];
  }
};

const onSubmit = async() => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;

  const body = {
    plan: selectedPlan.value._id,
    general: event.value,
    guestApp: {
      font: useWorkspaceStore().identity?.font,
      primaryColor: useWorkspaceStore().identity?.primaryColor,
    },
  };

  const response = await useEventStore().addEvent(body);

  if (response?.event) {
    await createDefaultTemplates(response.event);
  }

  isSubmitting.value = false;

  if (response?.event) {
    useToast(useT('event.created'));
    useDialogStore().close('eventCreate');

    await navigateTo(`/events/${response.event._id}/guests`);

    if (selectedPlan.value?.config?.tier !== 'basic') {
      useDialogStore().open('eventCollaboratorsAdd');
    }
  }

  if (response?.errorStatus === 403) {
    selectedPlan.value = null;
  }
};

const createDefaultTemplates = async(event) => {
  await useEventStore().fetchEvent({ _id: event._id });

  // Email template - ticket
  const emailDefaultPreset = await useBuilderStore().getPresetTemplate({
    type: 'email',
    id: 'email-ticket-1',
    replaces: {
      ...(selectedPlan.value?.config?.tier === 'basic'
        ? {
          '{primaryColor}': useTailwindConfig.colors.primary['500'],
        }
        : {}),
    },
  });
  const emailTemplateParams = {
    ...emailDefaultPreset.params,
    isInitialized: false,
    attachments: {
      calendarEvent: true,
      guestTicket: true,
    },
  };

  event?.general?.langs?.forEach((lang) => {
    emailTemplateParams.attachments[`_${lang}`] = { // TODO: move to preset
      uploadedFiles: [],
      eventTitle: event?.general?.title,
    };
  });

  const emailTemplate = await useTemplatesStore().createTemplate({
    event: event._id,
    type: 'email',
    name: useT('builder.category.ticket'),
    thumbnail: emailDefaultPreset.thumbnail,
    params: emailTemplateParams,
    sections: emailDefaultPreset.sections,
  });

  // Page template - registration page
  const pageDefaultPreset = await useBuilderStore().getPresetTemplate({
    type: 'page',
    id: 'page-simple-1',
    replaces: {
      '{emailTemplateId}': emailTemplate._id,
      ...(selectedPlan.value?.config?.tier === 'basic'
        ? {
          '{primaryColor}': useTailwindConfig.colors.primary['500'],
        }
        : {}),
    },
  });
  const pageTemplateParams = {
    ...pageDefaultPreset.params,
    isInitialized: false,
  };

  const pageTemplate = await useTemplatesStore().createTemplate({
    event: event._id,
    type: 'page',
    name: useT('event.eventPage.title'),
    thumbnail: pageDefaultPreset.thumbnail,
    params: pageTemplateParams,
    sections: pageDefaultPreset.sections,
  });

  useEventStore().updateEvent({
    pages: {
      activeTemplate: pageTemplate._id,
    },
  });
};

const handleBack = () => {
  isSlidingBack.value = true;
  selectedPlan.value = null;
};

const handleCancel = () => {
  isSlidingBack.value = false;
  selectedPlan.value = null;
  plans.value = [];
  useDialogStore().close('eventCreate');
};

// Watchers
watchEffect(() => {
  if (useDialogStore().eventCreate.isOpen) {
    fetchPlans();
  }
});
</script>
